$sky: rgba(100, 150, 255, 1);
$sun: rgba(255, 255, 50, 1);
$cloud: rgba(250, 250, 250, 1);

.loader_container{
  background: $sky;
  height: 100vh;
}

.weather-loader{
  width: 70px;
  height: 70px;
  background: $sun;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 35px);

  &:after,
  &:before{
    content: '';
    position: absolute;
    top: 40px;
    left: 20px;
    background: $cloud;
    display: block;
    width: 50px;
    height: 40px;
    border-radius: 50%;
    animation: cloud1 2.5s ease-in-out infinite alternate;
    box-shadow: 20px -15px 0 $cloud,
    40px 10px 0 $cloud,
    55px -15px 0 $cloud,
    75px 0px 0 $cloud;
  }

  &:before{
    left: -80px;
    top: 0;
    animation-name: cloud2;
  }
}

@keyframes cloud1 {
  from {  margin-left: 0; }
  to {  margin-left: 50px; }
}

@keyframes cloud2 {
  from {  margin-left: 0; }
  to {  margin-left: -50px; }
}