.modal{
  position: absolute;
  top: 0;
  z-index: 99;
  transform: translateY(-100%);
  text-align: center;
  transition: 1s ease;
  background: black;
  color: white;
  width: 60%;
  padding: 6% 0;
  &.active{
    transform: translateY(0%);
  }
  p{
    font-size: 0.8rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
  }
}