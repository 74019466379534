.favorite_container{
    &.active{
        .favorite__header{
            .btn_edit{
                margin: 0 auto;
                width: fit-content;
                display: block;
                text-decoration: none;
                background-color: #8cc152;
                font-size: 0.8rem;
            }
        }
        .favorite__grid{
            .favorite__grid-information{

                animation: twerk .3s infinite;

                @keyframes twerk {
                    0%{transform: rotate(0deg)}
                    33%{transform: rotate(-.5deg)}
                    66%{transform: rotate(.5deg)}
                    99.99%{transform: rotate(0deg)}
                }
                .delete-btn {
                    display: block;
                }
            }
        }

        .listEmpty{
            width: 100%;
            text-align: center;
            h1{
                font-weight: 400;
                text-transform: uppercase;
                font-size: 1rem;
            }
        }
    }
    .main{
        header{
            width: calc(100% - 20%);
            padding: 1rem 0;
        }
    }
    .favorite__header{
        padding: 5rem 0;
        text-align: center;
        h2 {
            margin: 2%;
            font-weight: 400;
            text-transform: uppercase;
            font-size: 1rem;
            &.fav{
                font-size: 2rem;
                font-weight: 400;
            }
        }

        .btn_edit{
            border: none;
            font-size: 0.8rem;
            padding: 0.8em;
            background: #F96;
            border-radius: 3px;
            color: white;
            font-weight: bold;
            margin: 0 0 1em;
        }
    }

    .previsionBtn {
        padding: 0;
        border: 0px;
        background-color: unset;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        color: #000;
        text-decoration: underline;
        margin: 0 0 1rem;
        cursor: pointer;
    }
}
.favorite {
    &__grid {
        margin-bottom: 5%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        gap: 1rem;

        &-information {
            padding: 2rem 2rem;
            position: relative;
            color: #fff;
            width: 100%;
            position: relative;

            .delete-btn {
                width: 40px;
                height: 40px;
                border-radius: 40px;
                background-color: #fff;
                border: 0px;
                position: absolute;
                transform: translate(30%, -30%);
                top: 0%;
                right: 0%;
                font-weight: 700;
                font-family: 'Montserrat', sans-serif;
                cursor: pointer;
                box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.10);
                display: none;
            }

            &-city {
                h2 {
                    font-size: 1.4rem;
                    text-align: center;
                    text-transform: uppercase;
                }
    
                h3 {
                    font-size: 4rem;
                    text-align: center;
                    margin: 1rem 0 2rem;
                }
            }

            &-container {
                // display: flex;
                position: relative;
                transition: transform .4s ease;

                &.slide {
                    transform: translateX(-112%);
                }
            }

            &-prevision {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 2rem;
                position: absolute;
                bottom: 0;
                right: 50%;
                transform: translate(calc(150% + 2rem), 0px);
                width: 100%;

                &-day {
                    text-align: center;
                    min-width: 6vw;

                    h4 {
                        text-transform: uppercase;
                        font-size: .8rem;
                    }

                    span {
                        display: block;

                        &.icon {
                            margin: .5rem 0;
                            img{
                                width: 32px;
                                height: 32px;
                            }
                        }

                        &:last-child {
                            font-size: 1rem;
                        }
                    }
                }
            }

            &-prevision-week {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 2rem;

                &-day {
                    text-align: center;

                    h4 {
                        text-transform: uppercase;
                        font-size: .8rem;
                    }

                    span {
                        display: block;

                        &.icon {
                            margin: .5rem 0;
                            img{
                                width: 32px;
                                height: 32px;
                            }
                        }

                        &:last-child {
                            font-size: 1rem;
                        }
                    }
                }
            }

            &-image {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                z-index: -1;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.300);
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .favorite {
        &__grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .favorite__grid-information-prevision-day {
        min-width: 12vw;
    }
}

@media screen and (max-width: 768px) {
    .favorite {
        &__grid {
            grid-template-columns: 1fr;
        }
    }
    .favorite__grid-information-prevision-day {
        min-width: 33vw;
    }
}

@media screen and (max-width: 400px) {
    .favorite__grid-information-prevision-day {
        min-width: 20vw;
    }
}