//.formulaire{
//  text-align: center;
//  button.searchBtn{
//    transition: .4s ease;
//    text-align: center;
//    padding: 3% 15%;
//    border-radius: 25px;
//    border: 0;
//    background-color: white;
//    color: black;
//    margin: 6% 0;
//    font-weight: 400;
//    text-transform: uppercase;
//    font-size: 1rem;
//    font-family: "Montserrat", sans-serif;
//    &:hover{
//      background-color: #6496ff;
//      color: white;
//      border: 1px solid white;
//    }
//  }
//  .form__group {
//    position: absolute;
//    left: 50%;
//    top: 10%;
//    transform: translateX(-50%);
//    padding: 15px 0 0;
//    width: 25%;
//  }
//
//  .form__field {
//    font-family: inherit;
//    width: 100%;
//    border: 0;
//    border-bottom: 2px solid white;
//    outline: 0;
//    font-size: 1.3rem;
//    color: white;
//    padding: 7px 0;
//    background: transparent;
//    transition: border-color 0.2s;
//
//    &::placeholder {
//      color: transparent;
//    }
//
//    &:placeholder-shown ~ .form__label {
//      font-size: 1.3rem;
//      cursor: text;
//      top: 20px;
//    }
//  }
//
//  .form__label {
//    position: absolute;
//    top: 0;
//    display: block;
//    transition: 0.2s;
//    font-size: 1rem;
//    color: white;
//  }
//
//  .form__field:focus {
//    ~ .form__label {
//      position: absolute;
//      top: 0;
//      display: block;
//      transition: 0.2s;
//      font-size: 1rem;
//      color: white;
//      font-weight:700;
//    }
//    padding-bottom: 6px;
//    font-weight: 700;
//    border-width: 3px;
//  }
//  .form__field{
//    &:required,&:invalid { box-shadow:none; }
//  }
//  .search_alreadyUse{
//    .form__group{
//      right: -5%;
//      left: unset;
//    }
//  }
//}
//

.form__group{
  display: none;
  position: absolute;
  z-index: 99;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5%;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
  color: #000;
  justify-content: center;
  align-items: center;
  &.active{
    display: flex;
  }

  .form {
    color: #fff;
    width: 80%;
    label {
      font-family: "Montserrat", sans-serif;
      font-size: 2rem;
      font-weight: 700;
    }
    input {
      display: block;
      margin: 1rem 0;
      width: 100%;
      padding: .5rem;
      font-size: 2rem;
    }
    button {
      border: 0px;
      padding: .5rem 1rem;
      background: #fff;
      color: #000;
      font-size: 1rem;
    }
  }
}