*, body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-size: 62.5%;
}


h2, h3, h4 {
    font-family: 'Montserrat', sans-serif;
}

p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}
header {
    position: fixed;
    z-index: 99;
    width: 60%;
    padding: 1rem 5rem;

    nav {
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            div.left{
                display: flex;
            }
            li {
                list-style: none;
                font-family: 'Montserrat', sans-serif;
                font-size: .8rem;
                color: #000;
                position: relative;
                cursor: pointer;
                a {
                    text-decoration: none;
                    font-family: 'Montserrat', sans-serif;
                    font-size: .8rem;
                    color: #000;
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -2px;
                        z-index: -1;
                        height: 1px;
                        background-color: black;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition: transform 0.3s ease-out;
                    }
                    &:hover::before {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}

.container {
    width: 80%;
    margin: 0 auto;
}

.position {
    height: 100%;
    min-height: 100vh;
    display: flex;

    &__information {
        position: relative;
        flex: 60%;
        background-color: #fff;
        padding: 6rem 5rem 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: .2rem;
        overflow: hidden;
        div.addFav{
            position: absolute;
            right: 9%;
        }
        &-date {
            h2 {
                font-weight: 400;
                text-transform: uppercase;
                font-size: 1rem;
            }
        }

        &-city {
            text-align: center;

            h2 {
                font-size: 2rem;
                font-weight: 400;
            }
    
            h3 {
                font-size: 6rem;
                font-weight: 700;
            }
        }
        &-prevision-week {
            background-color: white;
            padding: 1rem 2rem;
            position: fixed;
            top: 50%;
            left: 0;
            transform: translate(-100%, -50%);
            display: flex;
            flex-direction: column;
            gap: 2rem;
            box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
            transition: transform .4s ease;

            &.active {
                transform: translate(0px, -50%);
            }

            &-day {
                text-align: center;
                color: #000;

                h4 {
                    font-size: .8rem;
                }

                span {
                    display: block;

                    &.icon {
                        width: 50%;
                        margin: .4rem auto;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    &:last-child {
                        font-size: .8rem;
                    }
                }
            }

            button {
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                position: absolute;
                top: 30%;
                transform: rotate(90deg) translate(-50%, -50%);
                right: calc(0% - 3.2rem);
                border: 0px;
                background-color: #f4f4f4;
                padding: 1rem;
                cursor: pointer;
            }
        }
        &-prevision {
            display: flex;
            gap: 2rem;
            width: 100%;
            overflow-x: scroll;
            padding-bottom: 1.5rem;
            justify-content: space-between;
            h4 {
                font-size: .8rem;
                text-transform: uppercase;
                font-weight: 400;
            }

            span {
                font-size: 1rem;
                display: block;
                text-align: center;
            }

            &-day {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;

                span.icon {
                    display: flex;
                    width: 40px;
                    height: 40px;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }
    }
        
    &__image {
        flex: 40%;
        height: initial;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-blend-mode: darken;
        -webkit-box-shadow: inset 50px 0px 50px -30px rgba(0,0,0,0.35);
        box-shadow: inset 50px 0px 50px -30px rgba(0,0,0,0.35);
    }
}

@media screen and (max-width: 1024px) {
    header{
        padding: 2rem 2rem;
    }
    .position {
        &__information {
            padding: 5rem 2rem;
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        width: 90%;
    }
    main {
        &.favorite {
            header {
                width: 100%;
                nav {
                    ul {
                        li {
                            color: #000;
                            a {
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
        header {
            width: 100%;
            nav {
                ul {
                    li {
                        color: #fff;
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
        .position {
            position: relative;
            display: block;

            &__information {
                background-color: unset;
                width: unset;
                color: #fff;
                &-prevision {
                    gap: 1.5rem;
                }
            }

            &__image {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.300);
                }
            }
        }
    }
}